<template>
  <div class="home">
   <div class="container">
       <div class="basic-intro">
        本游戏的诞生源自于<br>
曾使 NFT 全球头部交易市场 OPENSEA 页面崩坏的传奇项目 PLOOT<br>
结合其原生代币 DDDD，趁着 GameFi 热潮，秉着 Play-To-Earn 精神<br>
必将在 BSC 上开启一段全新的致富体验<br>
    </div>
    <div class="card-selector">
        <div class="card">
            <div class="card-body">
                
            </div>
            <div class="btn">10000 {{game.token}}</div>
            <div class="buy-btn">Get More ${{game.token}}</div>
        </div>
        <div class="card">
            <div class="card-body">
                
            </div>
            <div class="btn">10000 {{game.token}}</div>
            <div class="buy-btn">Get More ${{game.token}}</div>
        </div>
        <div class="card">
            <div class="card-body">
                
            </div>
            <div class="btn">10000 {{game.token}}</div>
            <div class="buy-btn">Get More ${{game.token}}</div>
        </div>
        
    </div>
   </div>
    
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Home',
 
}
</script>
<style lang='scss'>
    
    .home{
        background-image: url(../assets/image/Home_bg.jpg);
        background-size: cover;
        background-position: center;
        width: 100%;
        min-width: 1280px;
        .container{
            margin: 0px auto;
        }
        .basic-intro   {
            color: #FFA900;
            font-size: 22px;
            text-align: center;
            padding-top:50px;
            line-height: 50px;
            font-family: bigfont;
        }
        .card-selector{
            display: flex;
            justify-content: space-between;
            text-align: center;
            width: 1000px;
            margin: 0px auto;
            margin-top:130px;
            padding-bottom:50px;
            
            .card{
                color: white;
                .card-body{
                    width:246px;
                    height: 400px;
                   background-image: url("../assets/image/Home_cards.png");
                background-size: cover;
                    margin: 0px auto;
                }
                .btn    {
                    width: 140px;
                    margin:0px auto;
height: 33px;
background: #FFA900;
border-radius: 5px;
                    margin-top: 30px;
                    line-height: 33px;
                    font-weight: bold;
font-size: 14px;

font-weight: 400;
color: #FFFFFF;
text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
                    
                }
                .buy-btn{
                    margin-top: 20px;
font-size: 12px;
font-weight: bold;
                }
                
            }
        }
    }
</style>
