<template>
  <div id="app">
    <page-nav></page-nav>
    
    <router-view/>
    <page-bottom></page-bottom>
  </div>
</template>
<script>
import PageNav from '@/components/nav'
import PageBottom from '@/components/bottom'
    
export default  {
     components: {
      PageNav,PageBottom
  }
    }</script>
<style lang="scss">
  html{
        background-color: black;
    }
    body{
        margin: unset;
        a{text-decoration: none;}    
a:visited{text-decoration: none;}    
a:hover {text-decoration: none;}    
a:active{text-decoration:none;} 
    }
    .container{
        width:1280px;
        margin: 0px auto;
    }
     @font-face {
    font-family: 'bigfont';
    src: url('./assets/css/font.ttf');
}
</style>
