<template>

    <div class="page-nav">
        <div class="container">
            <div class="avatar">
              <img src="../assets/image/DDDD_Logo.png" alt="">
            </div>
            <router-link class="nav-bar" :to="{name:'Battle'}"><div >首页</div></router-link>
            <div class="nav-bar">作战室</div>
            <router-link class="nav-bar" :to="{name:'Battle'}"><div>爆金战场</div></router-link>
            <div class="nav-bar">交易市场</div>
            <div class="wallet-info" v-if="connect">
                <div class="address">0x8888 .... 6666</div>
                <div class="line">{{game.token}} Price <div class="right">$ 0.88</div>
                </div>
                <div class="line">Balance <div class="right">100000</div>
                </div>
            </div>
            <div class="connect-wallet" v-else>
                Connect Metamask
            </div>
        </div>

    </div>
</template>

<script>
    export default {
data(){
    return{
        connect:false
    }
}
    }

</script>
<style lang='scss'>
    @font-face {
    font-family: 'bigfont';
    src: url('../assets/css/font.ttf');
    }

    .page-nav {
        height: 80px;
        width: 100%;
        min-width: 1280px;
        background-color: white;

        .container {
            width: 1280px;
            margin: 0px auto;
            display: flex;
            align-items: center;
    height: 80px;
            .avatar {
                cursor: pointer;
                
                margin-right: 150px;
                font-size: 0px;
                color: #FFA900;
                font-weight: bold;
                img{
                    height: 50px;
                }
            }

            .nav-bar {
                cursor: pointer;
                
                margin-right: 130px;
                font-size: 22px;
                color: #FFA900;
                position: relative;
font-family: bigfont;
                &.active {
                    font-size: 35px;

                    &:before {
                        content: ""
                    }
                }
            }

            .wallet-info {
                cursor: pointer;
                
                margin-left: auto;
                background-color: #FFA900;
                border-radius: 15px;
                padding: 8px;
                box-sizing: border-box;
                font-size: 12px;
                width: 190px;
                color: white;

                .address {
                    background-color: white;
                    color: #FFA900;
                    text-align: center;
                    line-height: 16px;
                    border-radius: 8px;
                    margin-bottom: 5px;

                }

                .line {
                    font-weight: bold;
                    display: flex;

                    .right {
                        margin-left: auto;
                    }
                }
            }
            .connect-wallet{
                cursor: pointer;
                background-color: #FFA900;
                height: 30px;
                line-height: 30px;
                padding:0px 10px;
                color: white;
                border-radius: 10px;
            }

        }
    }

</style>
