import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

const game_info = {
    token:"DDDD"
}

Vue.prototype.game = game_info

let app = new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

console.log(app)